<template>
  <div class="module-balance">
    <div v-if="user" class="module-balance-current module-balance__current">
      <div class="module-balance-current__title">Ваш баланс</div>
      <div class="module-balance-current__amount">200.00</div>
    </div>

    <div class="module-balance__title">Активность коллег</div>

    <div class="module-balance-table">
      <div
        v-for="(user, index) in users"
        :key="`mbt-user-${index}`"
        class="module-balance-table__row"
      >
        <div class="module-balance-table__col">
          {{ user.name }}
        </div>
        <div class="module-balance-table__col">
          {{ user.amount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventNavBarMixin from "@/mixins/eventNavBarMixin";
import themeMixin from "@/mixins/eventTheme";

export default {
  name: "ModuleBalance",
  mixins: [eventNavBarMixin, themeMixin],
  data() {
    return {
      users: [
        {
          name: "Роланд Г.",
          amount: "200.00",
        },
        {
          name: "Роман К.",
          amount: "100.00",
        },
        {
          name: "Иван Б.",
          amount: "0.00",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";

.module-balance {
  padding: 20px 15px;
  border-radius: 2px;
  background-color: $color-main;

  &__current {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
  }
}
.module-balance-current {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #fff;

  &__title {
    font-weight: 500;
    font-size: 14px;
  }
  &__amount {
    font-weight: 500;
    font-size: 24px;
  }
}

.module-balance-table {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 2px;
  background-color: rgba(#fff, 0.2);

  &__row {
    display: flex;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
  &__col {
    font-weight: 500;
    font-size: 12px;
    flex-grow: 1;

    &:first-child {
      padding-right: 16px;
    }
    &:last-child {
      text-align: right;
    }
  }
}
</style>
