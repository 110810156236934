<template>
  <div class="popup-block" :class="classList">
    <div v-click-outside="onHide" class="popup-block__inner">
      <div class="popup-block__close" @click="$emit('close-popup', $event)">
        <img src="../assets/images/cross.png" alt="Закрыть" />
      </div>
      <div class="popup-block__task">
        <div class="popup-block__title">
          Задание
          <div class="popup-block__days"><span>2</span> из 10</div>
        </div>
        <div class="popup-block__reward">
          Это задание принесет Вам
          <div class="popup-block__count">150.00</div>
        </div>
      </div>
      <div class="popup-block__desc">
        <p>
          В новогоднее путешествие ваша семья уехала отдыхать без вас (ведь
          кто-то должен работать), но новогодние открытки родственникам
          отправить все же придется.<br /><br />Задание: изобразите любыми
          возможными средствами вашу семью и сделайте новогоднее семейное фото
          на открытку.<br /><br /><small
            >P.S.: Графические редакторы запрещены к использованию.</small
          >
        </p>
      </div>
      <div class="popup-block__upload">
        <titled-headline size="small" />
        <div class="popup-block__dragdrop">
          <span>Перетащите файл или нажмите</span>
          <button>
            <img src="../assets/images/cross.png" alt="Загрузить" />
          </button>
        </div>
      </div>
      <div class="popup-block__submit">
        <ui-button variation="white" class="container-gifts__button">
          Отправить
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import UiButton from "./uiButton";
import TitledHeadline from "./titledHeadline";
import themeMixin from "@/mixins/eventTheme";

export default {
  name: "PopupBlock",
  mixins: [themeMixin],
  directives: {
    ClickOutside,
  },
  components: {
    UiButton,
    TitledHeadline,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      return [this.value && "is-opened"];
    },
  },
  methods: {
    onHide() {
      this.value && this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.popup-block {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: auto;
  padding: 20px;
  background-color: rgba(#292929, 0.3);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  &.is-opened {
    opacity: 1;
    pointer-events: all;
  }

  &__inner {
    margin: auto;
    width: 800px;
    height: 430px;
    position: relative;
    padding: 35px 40px;
    background: $color-main;
    border-radius: 2px;
    display: grid;
    grid-template-columns: 120px 1fr 200px;
    grid-template-rows: 1fr auto;
    grid-gap: 30px;
  }

  &__task {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  &__days {
    span {
      font-size: 36px;
    }
  }
  &__reward {
    justify-self: flex-end;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  &__count {
    margin-top: 26px;
    font-size: 24px;
    width: 100px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(#fff, 0.3);
    text-align: center;
  }
  &__desc {
    font-size: 12px;
    border: 1px solid #fff;
    border-radius: 2px;
    display: flex;
    padding: 30px;
    overflow-y: auto;
    p {
      margin: auto;
    }
  }
  &__upload {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__dragdrop {
    width: 100%;
    height: 140px;
    padding: 10px 20px;
    font-size: 14px;
    background: rgba(#fff, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      text-align: center;
      line-height: 16px;
      margin-bottom: 20px;
    }
    button {
      width: 40px;
      height: 40px;
      padding: 5px;
      border: 1px solid #fff;
      color: #fff;
      border-radius: 1px;
      background: rgba(#fff, 0);
      transition: background-color 0.3s;
      cursor: pointer;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
      &:hover {
        background: rgba(#fff, 0.3);
        transition: background-color 0.3s;
      }
    }
  }
  &__submit {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
  }
  &__close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    & img {
      width: 40px;
      height: 40px;
      transform: rotate(45deg);
    }
  }
}
</style>
