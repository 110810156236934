<template>
  <div class="logo" :class="classList">
    <div class="logo__image">
      <img
        :src="
          require(`../assets/images/${
            variation === 'header' ? 'logo' : 'logo-white'
          }.png`)
        "
        alt="Оставайтесь дома"
      />
    </div>

    <div class="logo__title">
      {{ variation === "header" ? "Оставайтесь дома!" : "2020 - 2021" }}
    </div>
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "Logo",
  mixins: [themeMixin],
  props: {
    variation: {
      type: String,
      default: "header", // footer
    },
  },
  computed: {
    classList() {
      return [this.variation && `is-${this.variation}`];
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.logo {
  $this: &;
  display: flex;
  align-items: flex-end;

  &.is-footer {
    flex-direction: column;
    align-items: center;

    #{$this}__image {
      margin-right: 0;
      margin-bottom: 5px;
      width: 25px;
    }
    #{$this}__title {
      font-size: 10px;
      text-align: center;
    }
  }

  &__image {
    display: flex;
    align-items: flex-start;
    width: 45px;
    margin-right: 5px;

    img {
      max-width: 100%;
    }
  }
  &__title {
    font-weight: 300;
    font-size: 12px;
    color: rgba(var(--primary), 1);
  }
}
</style>
