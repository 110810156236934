<template>
  <div class="module-welcome">
    <template v-if="user">
      <div class="module-welcome__headline">
        Добрый день{{ user.username && `, ${user.username}` }}!
      </div>
      <div class="module-welcome__title">День челленджа</div>
      <div class="module-welcome__days"><span>2</span> из 10</div>
      <div class="module-welcome__message">У вас 1 новое задание</div>
      <div class="module-welcome__buttons">
        <ui-button
          class="module-welcome__button"
          @click="$emit('open-popup', $event)"
        >
          Открыть
        </ui-button>
        <ui-button
          class="module-welcome__button"
          variation="outline"
          @click="onSignOut"
        >
          Выход
        </ui-button>
      </div>
    </template>
    <template v-else>
      <div class="module-welcome__buttons">
        <ui-button class="module-welcome__button" @click="onSignIn">
          Войти
        </ui-button>
        <ui-button
          class="module-welcome__button"
          variation="outline"
          @click="onSignUp"
        >
          Регистрация
        </ui-button>
      </div>
      <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.mobile"
        :max-width="$vuetify.breakpoint.mobile ? null : 400"
      >
        <v-card :loading="loading">
          <v-card-title class="headline">
            {{ dialogMode === "signin" ? "Вход" : "Регистрация" }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="code"
              v-if="isAuthByCode && dialogMode === 'signup'"
              label="Код"
              persistent-hint
              hint="Введите код, присланный организатором"
            />
            <v-text-field v-model="username" label="Имя и Фамилия" />
            <v-text-field v-model="password" label="Пароль" />
            <v-text-field
              v-model="confirmPassword"
              v-if="false && dialogMode === 'signup'"
              type="password"
              label="Подтвердите пароль"
            />
            <v-alert text type="warning" v-if="alert">
              {{ alert }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="dialog = false">
              Отмена
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="onAuthFormSubmit"
              :disabled="!formIsValid"
            >
              {{ dialogMode === "signin" ? "Войти" : "Регистрация" }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import UiButton from "./uiButton";
import eventNavBarMixin from "@/mixins/eventNavBarMixin";
import themeMixin from "@/mixins/eventTheme";

export default {
  name: "ModuleWelcome",
  mixins: [eventNavBarMixin, themeMixin],
  components: {
    UiButton,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.module-welcome {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  padding: 20px 12px;
  border-radius: 2px;
  border: 2px solid rgba(var(--primary), 1);
  background-color: rgba(var(--secondary), 1);
  color: rgba(var(--primary), 1);
  border-radius: 2px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   z-index: -1;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-47%, -50%);
  //   pointer-events: none;
  //   width: 266px;
  //   height: 270px;
  //   background: url("../assets/images/module-welcome-bg.png") center/contain
  //     no-repeat;
  // }

  &__headline {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }
  &__title {
    font-weight: 300;
    font-size: 12px;
  }
  &__days {
    margin-top: -8px;
    font-weight: 300;
    font-size: 12px;

    span {
      padding-right: 4px;
      font-weight: 600;
      font-size: 36px;
    }
  }
  &__message {
    margin: 10px 0 15px;
    font-weight: 600;
    font-size: 14px;
  }
  &__buttons {
    display: grid;
    gap: 8px;
  }
}
</style>
