<template>
  <nav class="layoun-nav">
    <a class="layoun-nav__link" href="#">Вопрос организаторам</a>
    <a class="layoun-nav__link" href="#">Архив заданий</a>
    <a class="layoun-nav__link" href="#">Техническая поддержка</a>
  </nav>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "LayounNav",
  mixins: [themeMixin],
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.layoun-nav {
  display: flex;
  align-items: center;
  justify-content: center;

  &__link {
    margin: 0 10px;
    padding: 4px 16px;
    font-weight: 300;
    font-size: 12px;
    text-decoration: none;
    color: rgba(var(--primary), 1) !important;
    transition: color 0.2s;

    &:hover {
      color: $color-main;
      text-decoration: underline;
    }
  }
}
</style>
