<template>
  <grid-month>
    <day-of-month day="30" disabled />
    <day-of-month
      v-for="(item, index) in 31"
      :key="`day-${index + 1}`"
      :day="index + 1"
      :active="index + 1 === 2"
    />
    <day-of-month day="1" disabled />
    <day-of-month day="2" disabled />
    <day-of-month day="3" disabled />
  </grid-month>
</template>

<script>
import GridMonth from "../gridMonth";
import DayOfMonth from "../dayOfMonth";
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "AdventDemoQuest",
  mixins: [themeMixin],
  components: {
    GridMonth,
    DayOfMonth,
  },
};
</script>