<template>
  <div class="menu">
    <h4 class="menu__header" v-if="false">
      {{ title }}
    </h4>
    <div class="menu__list" :value="selectedItem">
      <ui-button
        v-for="(item, index) in items"
        :key="index"
        @click="onItemSelect(item.id)"
        variation="menu"
        :is-active="selectedItem === index"
        :is-calendar="item.title === 'Календарь'"
        >{{ item.title }}</ui-button
      >
    </div>
  </div>
</template>

<script>
import uiButton from "../uiButton";
import eventMenuMixin from "../../../../mixins/eventMenuMixin";

export default {
  components: { uiButton },
  name: "EventDemoMenu",
  mixins: [eventMenuMixin],
};
</script>

<style lang="scss">
.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid rgba(var(--primary), 1);
  background-color: rgba(var(--secondary), 1);
  border-radius: 2px;
  padding: 10px;

  &__header {
    font-weight: normal;
    margin-bottom: 10px;
  }
  &__list {
    display: grid;
    grid-gap: 10px;

    // flex-direction: column;
    // justify-content: center;
    // margin: 10px 0;
  }
}
</style>
