<template>
  <div class="module-gift">
    <div class="module-gift__title">
      {{ title }}
    </div>
    <div class="module-gift__amount">
      {{ amount }}
    </div>
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "ModuleGift",
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      default: "0",
    },
    amount: {
      type: [String, Number],
      default: "0",
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.module-gift {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70px;
    padding: 12px;
    border: 1px solid #fff;
    text-align: center;
    line-height: 1.2;
    font-size: 14px;
    background-color: rgba(#fff, 0.1);
  }
  &__amount {
    margin-top: 8px;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
  }
}
</style>
