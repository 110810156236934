<template>
  <v-fade-transition>
    <beautiful-chat
      v-if="user"
      :participants="participants"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messages"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :icons="icons"
      :open="openChat"
      :showEmoji="true"
      :showFile="false"
      :showEdition="true"
      :showDeletion="false"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="true"
      :showCloseButton="true"
      :showHeader="false"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage"
    />
  </v-fade-transition>
</template>

<script>
import Vue from "vue";
import Chat from "vue-beautiful-chat";
import { mapActions, mapGetters, mapMutations } from "vuex";
Vue.use(Chat);

import CloseIcon from "@/assets/chat/close-icon.png";
import OpenIcon from "@/assets/chat/logo-no-bg.svg";
import FileIcon from "@/assets/chat/file.svg";
import CloseIconSvg from "@/assets/chat/close.svg";

import themeMixin from "@/mixins/eventTheme";

export default {
  name: "EventChat",
  mixins: [themeMixin],
  data() {
    return {
      icons: {
        open: {
          img: OpenIcon,
          name: "default",
        },
        close: {
          img: CloseIcon,
          name: "default",
        },
        file: {
          img: FileIcon,
          name: "default",
        },
        closeSvg: {
          img: CloseIconSvg,
          name: "default",
        },
      },
      messageList: [
        {
          type: "text",
          author: `support`,
          data: { text: `Чем могу вам помочь?` },
        },
        {
          type: "text",
          author: `me`,
          data: {
            text: `Ничем! Все супер :) Просто зашел сказать спасибо :)))`,
          },
        },
      ], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: false, // to determine whether the chat window should be open or closed
      showTypingIndicator: "", // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: "#4e8cff",
          text: "#ffffff",
        },
        launcher: {
          bg: "#4e8cff",
        },
        messageList: {
          bg: "#ffffff",
        },
        sentMessage: {
          bg: "#4e8cff",
          text: "#ffffff",
        },
        receivedMessage: {
          bg: "#eaeaea",
          text: "#222222",
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867",
        },
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: false, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    };
  },
  computed: {
    ...mapGetters({
      chatItem: "event/chatItem",
      user: "event/user",
    }),
    ...mapGetters({
      messages: "event/chat/messages",
      participants: "event/chat/participants",
    }),
    userId() {
      return this.user ? this.user.id : null;
    },
  },
  watch: {
    userId() {
      this.resetChat();
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
    }),
    ...mapMutations({
      appendMyMessage: "event/chat/appendMyMessage",
      resetChat: "event/chat/resetChat",
    }),
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
      }
    },
    async onMessageWasSent(message) {
      this.appendMyMessage(message);
      const { type, data } = message;
      const { _id: itemId } = this.chatItem;
      const text = data[type];
      const command = "chat|sendMessage";
      const params = {
        message: text,
        chatId: "tech-support",
      };
      try {
        await this.runCommand({
          itemId,
          command,
          params,
        });
      } catch (err) {
        if (err && err.message) {
          alert(err.message);
        }
      }
    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true;
      this.newMessagesCount = 0;
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false;
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      // console.log("Emit typing event");
    },
    editMessage(message) {
      const m = this.messageList.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
  },
};
</script>