<template>
  <div class="footer">
    <layout-nav class="footer__nav" />
    <logo variation="footer" />
  </div>
</template>

<script>
import Logo from "./logo";
import LayoutNav from "./layoutNav";
import themeMixin from "@/mixins/eventTheme";

export default {
  name: "Footer",
  mixins: [themeMixin],
  components: {
    Logo,
    LayoutNav,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.footer {
  margin-top: 12px;

  &__nav {
    margin-bottom: 32px;
  }
}
</style>
