<template>
  <div class="titled-headline" :class="classList">
    <div class="titled-headline__text">Ваш календарь</div>
    <div class="titled-headline__image">
      <img src="../assets/images/title.png" alt="Волшебных событий" />
    </div>
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "TitledHeadline",
  mixins: [themeMixin],
  props: {
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    classList() {
      return [this.size && `is-size--${this.size}`];
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.titled-headline {
  $this: &;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.is-size {
    &--small {
      #{$this}__text {
        font-size: 14px;
      }
    }
  }

  &__text {
    font-weight: 300;
    font-size: 24px;
    color: rgba(var(--primary), 1);
  }
  &__image {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 415px;

    img {
      max-width: 100%;
      font-size: 36px;
      font-style: italic;
    }
  }
}
</style>
