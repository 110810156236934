<template>
  <div class="container-gifts">
    <div class="container-gifts__headline">Подарки</div>
    <div class="container-gifts__content">
      <slot />
    </div>
    <ui-button variation="outline" class="container-gifts__button">
      Смотреть все
    </ui-button>
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
import UiButton from "./uiButton";
export default {
  name: "ContainerGifts",
  mixins: [themeMixin],
  components: {
    UiButton,
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";

.container-gifts {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 18px 24px 30px;
  background-color: $color-main;

  &__headline {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
  }
  &__content {
    flex-grow: 1;
    margin: 24px 0;
    width: 100%;

    > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
</style>
