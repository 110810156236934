var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"background-color":"transparent"},attrs:{"dark":""}},[_c('div',{staticClass:"page-main",style:(Object.assign({}, (_vm.bgImage
        ? {
            backgroundImage: ("url(" + _vm.bgImage + ")"),
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }
        : null),
      {'--primary': _vm.theme.primary || '',
      '--secondary': _vm.theme.secondary || '',
      '--primary-darken-5': _vm.theme.primaryDarken5 || '',
      '--primary-darken-10': _vm.theme.primaryDarken10 || '',
      '--primary-darken-15': _vm.theme.primaryDarken15 || '',
      '--secondary-darken-10': _vm.theme.secondaryDarken10 || '',
      '--secondary-lighten-10': _vm.theme.secondaryLighten10 || '',
      '--calendar': _vm.theme.calendar || ''}))},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"header"},[_vm._t("header",[_c('logo'),_c('titled-headline')])],2),_c('div',{staticClass:"content",style:({
          gridTemplateColumns: [
            '200px',
            '1fr' ].concat( (_vm.rightMenu === true ? ['200px'] : []) ).join(' '),
        })},[_vm._t("left-menu",[_c('div',{staticClass:"content__aside is-left"},[_c('event-menu'),_c('module-welcome',{on:{"open-popup":function($event){_vm.popupOpened = true}}}),(false)?_c('module-balance'):_vm._e()],1)]),_c('div',{staticClass:"content__main"},[(_vm.activeItem)?_c('event-content',{attrs:{"content":_vm.activeItem.content,"mapping":_vm.contentComponentsMapping}}):_vm._e()],1),(_vm.rightMenu === true)?_vm._t("right-menu",[_c('div',{staticClass:"content__aside is-right"},[_c('container-gifts',_vm._l((_vm.gifts),function(gift,index){return _c('module-gift',{key:("gift-" + index),attrs:{"title":gift.title,"amount":gift.amount}})}),1)],1)]):_vm._e()],2),_c('layout-footer'),(_vm.chatEnabled)?_c('event-chat'):_vm._e()],1),_c('popup-block',{on:{"close-popup":function($event){_vm.popupOpened = false}},model:{value:(_vm.popupOpened),callback:function ($$v) {_vm.popupOpened=$$v},expression:"popupOpened"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }