<template>
  <button
    class="ui-button"
    v-bind="$attrs"
    :class="classList"
    @click="
      $emit('click', $event);
      $event.stopPropagation();
    "
  >
    <span>
      <slot />
    </span>
  </button>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "UiButton",
  inheritAttrs: false,
  mixins: [themeMixin],
  props: {
    variation: {
      type: String,
      default: null, // white, outline
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isCalendar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      return [
        this.variation && `is-variation--${this.variation}`,
        this.isActive && "is-active",
        this.isCalendar && "is-calendar",
      ];
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.ui-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 24px;
  min-height: 35px;
  border: none;
  outline: none;
  border-radius: 35px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  background-color: rgba(var(--primary), 1);
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;

  // &:focus {
  //   box-shadow: 0 0 0 2px #18a0fb;
  // }
  &:hover {
    background-color: rgba(var(--primary-darken-5, 0.8));
  }
  &:active {
    background-color: rgba(var(--primary-darken-15, 0.8));
  }

  // variations
  &.is-variation {
    &--outline {
      color: rgba(var(--primary), 1);
      background-color: rgba(var(--primary), 0);
      border: 2px solid rgba(var(--primary), 1);

      &:hover {
        background-color: rgba(var(--primary), 0.2);
      }
      &:active {
        background-color: rgba(var(--primary-darken-10), 0.2);
      }
    }
    &--white {
      color: var(--secondary);
      background: var(--primary);

      &:hover {
        background-color: var(--primary-darken-5);
      }
      &:active {
        background-color: var(--primary-darken-15);
      }
    }
    &--menu {
      color: rgba(var(--primary), 1);
      background: none;

      &.is-calendar {
        background-color: rgba(var(--calendar), 0);
        font-size: 16px;
        border: 2px solid rgba(var(--calendar));
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

        &.is-active {
          color: #333;
          background-color: rgba(var(--calendar), 1);
        }

        &:hover {
          color: #333;
          background-color: rgba(var(--calendar), 1);
        }
        &:focus {
          color: #333;
          background-color: rgba(var(--calendar), 1);
        }
      }

      &:not(.is-calendar) {
        > span {
          display: inline-block;
          border-bottom: 2px solid rgba(var(--calendar), 0);
        }
        &:hover > span {
          border-bottom: 2px solid rgba(var(--calendar), 0.4);
        }
        &:active > span {
          border-bottom: 2px solid rgba(var(--calendar), 0.4);
        }
        &.is-active > span {
          border-bottom: 2px solid rgba(var(--calendar), 1);
        }
      }
    }
  }
}
</style>
