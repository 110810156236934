<template>
  <v-app dark style="background-color: transparent">
    <div
      class="page-main"
      :style="{
        ...(bgImage
          ? {
              backgroundImage: `url(${bgImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }
          : null),
        '--primary': theme.primary || '',
        '--secondary': theme.secondary || '',
        '--primary-darken-5': theme.primaryDarken5 || '',
        '--primary-darken-10': theme.primaryDarken10 || '',
        '--primary-darken-15': theme.primaryDarken15 || '',
        '--secondary-darken-10': theme.secondaryDarken10 || '',
        '--secondary-lighten-10': theme.secondaryLighten10 || '',
        '--calendar': theme.calendar || '',
      }"
    >
      <div class="container">
        <header class="header">
          <slot name="header">
            <logo />
            <titled-headline />
          </slot>
        </header>

        <div
          class="content"
          :style="{
            gridTemplateColumns: [
              '200px',
              '1fr',
              ...(rightMenu === true ? ['200px'] : []),
            ].join(' '),
          }"
        >
          <slot name="left-menu">
            <div class="content__aside is-left">
              <event-menu />
              <module-welcome @open-popup="popupOpened = true" />
              <module-balance v-if="false" />
            </div>
          </slot>
          <div class="content__main">
            <!-- Контент события -->
            <event-content
              v-if="activeItem"
              :content="activeItem.content"
              :mapping="contentComponentsMapping"
            />
            <!-- Контент события / Конец -->
          </div>
          <slot name="right-menu" v-if="rightMenu === true">
            <div class="content__aside is-right">
              <container-gifts>
                <module-gift
                  v-for="(gift, index) in gifts"
                  :key="`gift-${index}`"
                  :title="gift.title"
                  :amount="gift.amount"
                />
              </container-gifts>
            </div>
          </slot>
        </div>

        <layout-footer />

        <event-chat v-if="chatEnabled" />
      </div>

      <!-- popup -->
      <popup-block v-model="popupOpened" @close-popup="popupOpened = false" />
    </div>
  </v-app>
</template>

<script>
import "./assets/styles/style.scss";
import "./assets/styles/_variables.scss";

// base event mixin
import eventMixin from "@/mixins/eventMixin.js";

// components import
import TitledHeadline from "./components/titledHeadline";
import Logo from "./components/logo";
import ModuleWelcome from "./components/moduleWelcome";
import ModuleBalance from "./components/moduleBalance";
import ModuleGift from "./components/moduleGift";

import LayoutFooter from "./components/layoutFooter";
import ContainerGifts from "./components/containerGifts";
import PopupBlock from "./components/popupBlock";

// event components
import EventMenu from "./components/content/eventMenu";
import EventChat from "@/components/event/Chat.vue";
// import EventNavUserMenu from "./components/content/eventUserNav";

// content components
import Quest from "./components/quest/Quest";
export default {
  name: "AdventEventLayoutOne",
  mixins: [eventMixin],
  props: ["eventId", "mapping", "left-menu", "right-menu"],
  components: {
    TitledHeadline,
    Logo,
    ModuleWelcome,
    ModuleBalance,
    ModuleGift,
    LayoutFooter,
    ContainerGifts,
    PopupBlock,
    EventMenu,
    EventChat,
    // EventNavUserMenu,
  },
  metaInfo() {
    return {
      links: [
        {
          rel: "preconnect",
          href: "https://fonts.gstatic.com",
        },
        {
          rel: "stylesheet",
          href:
            "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap",
        },
      ],
    };
  },
  data() {
    return {
      popupOpened: false,
      gifts: [
        {
          title: "Неделя оплачиваемых отгулов",
          amount: "2500.00",
        },
        {
          title: "+2 дня к отпуску",
          amount: "1500.00",
        },
        {
          title: "Прыжок с парашютом",
          amount: "1500.00",
        },
      ],
      contentComponentsMapping: {
        quest: Quest,
        ...this.mapping,
      },
    };
  },
  methods: {
    showPopup() {
      this.popupOpened = true;
    },
    hidePopup() {
      this.popupOpened = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss">
.page-main {
  position: relative;
}
</style>
